import React from 'react'
import '../css/Loading.css'

const Loading = () => {
    return (
        <div class="loadingio-spinner-spinner-o1tfeh0shl"><div class="ldio-ql6jhbhx8n">
            <div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div>
        </div></div>
    )
}

export default Loading