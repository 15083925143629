import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Modal from '../../../../Componentes/Modal';
import { format } from 'date-fns';


const StepsConfirm = ({ formStep1, sendData, loadingModal, data, startDate, fileSelect, setFileSelect, phonestate }) => {

    const [newDate, setNewDate] = useState([])

    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease',
            once: true
        });
        console.log("FORM STEP ", formStep1);
        console.log("DATA RESUMEN?? ", data);
    }, [])






    return (
        <div data-aos="fade-up" >
            <h6 className="step-steps1Title3  " > Confirmar datos </h6>
            <hr className='hrDivider' />
            <div className="fadeTop">
                <h3 className="step-sub-title">Tipo de póliza y compañía de seguros</h3>
            </div>
            <Modal loadingModal={loadingModal} />
            {
                formStep1.tipoPoliza !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Tipo de póliza
                        </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text">
                            {formStep1.tipoPoliza}
                        </div>
                    </div>
                )
            }

            {
                data.nombreTomador !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Nombre de la empresa
                        </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text ">
                            {data.nombreTomador}
                        </div>
                    </div>)
            }

            {
                data?.selectSeguro !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Compañía de seguro </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text ">

                            {data?.selectSeguro}
                        </div>
                    </div>
                )
            }
            <hr className='hrDivider' />
            <div className="fadeTop">
                <h3 className="step-sub-title">Datos personales</h3>
            </div>




            {
                formStep1?.titularObeneficiario !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Titular o beneficiario</div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text ">
                            {formStep1?.titularObeneficiario}
                        </div>
                    </div>
                )
            }
            {
                data?.nombreTitularPoliza !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Nombre del titular de la Póliza</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.nombreTitularPoliza
                            }
                        </div>
                    </div>
                )
            }

            {
                data?.cedulaTitular !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label"> Cédula de identidad del titular</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text-2" >
                            {
                                data?.cedulaTitular
                            }
                        </div>
                    </div>
                )
            }


            {
                data?.emailTitular !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Correo electrónico del titular</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.emailTitular
                            }
                        </div>
                    </div>
                )
            }

            {
                phonestate?.phoneTitular !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Número de teléfono del titular </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text">
                            {
                                phonestate?.phoneTitular
                            }
                        </div>
                    </div>
                )
            }


            {
                data?.nombreTitularPoliza2 !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Nombre del titular de la Póliza</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.nombreTitularPoliza2
                            }
                        </div>
                    </div>
                )
            }

            {
                data?.cedulaTitular2 !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Cédula de identidad del titular</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text-2" >
                            {
                                data?.cedulaTitular2
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.emailTitular2 !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Correo electrónico del titular</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.emailTitular2
                            }
                        </div>
                    </div>
                )
            }

            {
                phonestate?.phoneTitular2 !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Número de teléfono del titular </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text">
                            {
                                phonestate?.phoneTitular2
                            }
                        </div>
                    </div>
                )
            }


            {
                data?.nombreBeneficiarioPoliza !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Nombre del beneficiario  </div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.nombreBeneficiarioPoliza
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.apellidoBeneficiarioPoliza !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Apellido del beneficiario de la póliza</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.apellidoBeneficiarioPoliza
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.cedulaBeneficiario !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Cédula de identidad del beneficiario</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text-2" >
                            {
                                data?.cedulaBeneficiario
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.emailBeneficiario !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Correo electrónico del beneficiario</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.emailBeneficiario
                            }
                        </div>
                    </div>
                )
            }

            {
                phonestate?.phoneBeneficiario !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Número de teléfono del beneficiario </div>
                        <div className="col-md-8 col-sm-6 col-xs-12 confirm-text">
                            {
                                phonestate?.phoneBeneficiario
                            }
                        </div>
                    </div>
                )
            }

            <hr className='hrDivider' />
            <div className="fadeTop">
                <h3 className="step-sub-title">Reembolso</h3>
            </div>

            {
                formStep1?.tipoReembolso !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Tipo de reembolso</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                formStep1?.tipoReembolso
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.informeMedico !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Informe médico</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.informeMedico
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.recipeIndicaciones !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Récipe e indicaciones</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.recipeIndicaciones
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.examenesRealizados !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Exámenes realizados</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.examenesRealizados
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.facturas !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Facturas </div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.facturas
                            }
                        </div>
                    </div>
                )
            }
            {
                data?.patologiaDiagnostico !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Patología o diagnóstico</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.patologiaDiagnostico
                            }
                        </div>
                    </div>
                )
            }
            {
                startDate !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Fecha de ocurrencia</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {format(startDate, 'dd/MM/yyyy')}
                        </div>
                    </div>
                )
            }
            {
                data?.montoTotal !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Monto</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                data?.montoTotal
                            }
                        </div>
                    </div>
                )
            }

            {
                formStep1?.opcionesReembolso !== '' && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Opción de Reembolso</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                formStep1?.opcionesReembolso
                            }
                        </div>
                    </div>
                )
            }

            {
                (formStep1?.opcionesReembolso == 'Zinli' && formStep1?.emailZinlinReembolso != '') && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Correo Electrónico de Zinli</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                formStep1?.emailZinlinReembolso
                            }
                        </div>
                    </div>
                )
            }

            {
                (formStep1?.opcionesReembolso == 'Cuenta Bancaria' && formStep1?.tipoCuenta != '') && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Tipo de Cuenta</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                formStep1?.tipoCuenta
                            }
                        </div>
                    </div>
                )
            }
            {
                (formStep1?.opcionesReembolso == 'Cuenta Bancaria' && formStep1?.numeroCuentaReembolso != '') && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">N° de Cuenta</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                formStep1?.numeroCuentaReembolso
                            }
                        </div>
                    </div>
                )
            }
            {
                (formStep1?.opcionesReembolso == 'Cuenta Bancaria' && formStep1?.nombreBancoReembolso != '') && (
                    <div className="row fadeTop">
                        <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Nombre de Banco</div>
                        <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                            {
                                formStep1?.nombreBancoReembolso
                            }
                        </div>
                    </div>
                )
            }
            <hr className='hrDivider' />

            <div className="fadeTop">
                <h6 className="step-sub-title">Documentos</h6>
            </div>

            {
                fileSelect.map((dateFile, i) => (

                    <>
                        {
                            i === 0 && (
                                <div className="row fadeTop" key={dateFile[0]?.name.substr(0, 10) + '_' + i}>
                                    <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Informe médico</div>
                                    <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                                        {
                                            dateFile?.map((file) => {
                                                return file?.name.substr(0, 10) + '' + file?.type.substr(5) + '; '
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            i === 1 && (
                                <div className="row fadeTop">
                                    <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Récipe e indicaciones (Fármacos/tratamiento)</div>
                                    <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                                        {
                                            dateFile?.map((file) => {
                                                return file?.name.substr(0, 10) + '' + file?.type.substr(5) + '; '
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            i === 2 && (
                                <div className="row fadeTop">
                                    <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Exámenes realizados (opcional)</div>
                                    <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                                        {
                                            dateFile?.map((file) => {
                                                return file?.name.substr(0, 10) + '' + file?.type.substr(5) + '; '
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }
                        {
                            i === 3 && (
                                <div className="row fadeTop">
                                    <div className="col-md-4 col-sm-6 col-xs-12  confirm-label">Facturas (Con los requerimientos del Seniat y sello húmedo de pagado)</div>
                                    <div className="col-md-8 col-sm-6 col-xs-12  confirm-text">
                                        {
                                            dateFile?.map((file) => {
                                                return file?.name.substr(0, 10) + '' + file?.type.substr(5) + '; '
                                            })
                                        }
                                    </div>
                                </div>
                            )
                        }

                    </>

                ))
            }
            <div className="fadeTop">
                <div className='buttonStyle_'>
                    <div onClick={sendData} className="btn btn-blue ">
                        Enviar Solicitud
                    </div>

                </div>

            </div>
        </div>
    )
}

export default StepsConfirm