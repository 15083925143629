import React, { useState, useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import CurrencyFormat from 'react-currency-format';
import PhoneInput from 'react-phone-input-2';
import InputMask from 'react-input-mask';

const Steps3 = ({ formStep1, setFormStep1, handleChange, handleBlur, errors, updateData }) => {

    const [isActiveCuentaBancaria, setIsActiveCuentaBancaria] = useState(false);
    const [isActiveZinli, setIsActiveZinlin] = useState(false);
    const [nombreBanco, setNombreBanco] = useState();

    const handleInput = (event) => {
        const formattedValue = event.target.value;
        
        const isAllDigits = formattedValue.replace(/[^0-9]/g, '').length === 20;
        
        if(isAllDigits){
            setFormStep1({ ...formStep1, numeroCuentaReembolso: formattedValue })
        }
    };

    useEffect(() => {
        if (formStep1.opcionesReembolso === 'Cuenta Bancaria') {
            setIsActiveZinlin(false)
            setIsActiveCuentaBancaria(true)
        } else if (formStep1.opcionesReembolso === 'Zinli') {
            setIsActiveCuentaBancaria(false)
            setIsActiveZinlin(true)
        } else {
            setIsActiveCuentaBancaria(false)
            setIsActiveZinlin(false)
        }
        console.log(updateData);
        console.log(formStep1)
    }, [formStep1.opcionesReembolso])


    useEffect(() => {
        AOS.init({
            duration: 1000,
            easing: 'ease',
            once: true
        });
    }, [])


    return (

        <div data-aos="fade-up">

            <h6 className="step-steps1Title">OPCIONES DE REEMBOLSO</h6>

            <div className="row mb-4">
                <div className="col-sm-12">
                    <label
                        className="block uppercase tracking-wide text-gray-400 text-sm font-bold mb-2"> Reembolso en
                    </label>
                    <select
                        defaultValue={formStep1?.opcionesReembolso}
                        name='selectSeguro'
                        onChange={(e) => {
                            // console.log("🚀 ~ Steps3 ~ e:", e)
                            if (e.target.value == 'Cuenta Bancaria') {
                                setIsActiveCuentaBancaria(true);
                                setIsActiveZinlin(false);
                            } else if (e.target.value == 'Zinli') {
                                setIsActiveCuentaBancaria(false);
                                setIsActiveZinlin(true);
                            }

                            setFormStep1({ ...formStep1, opcionesReembolso: e.target.value })
                        }}

                        className={`form-select appearance-none block w-full px-5 py-4 text-md font-normal text-gray-700 bg-white bg-clip-padding bg-no-repeat border border-solid  ${errors.opcionesReembolso ? 'border-red-600' : 'border-gray-300'}  rounded transition ease-in-out m-0 focus:text-gray-700 focus:bg-white focus:border-blue-600 focus:outline-none`}>
                        <option value={'Selecciona'}>Selecciona </option>
                        <option value={'Cuenta Bancaria'}>Cuenta Bancaria</option>
                        <option value={'Zinli'}>Zinli</option>
                    </select>

                    {
                        errors.opcionesReembolso === true && <span data-aos="zoom-in" className='errrorMsg'> Seleccione una opción de reembolso  </span>
                    }

                </div>
            </div>
            {
                isActiveCuentaBancaria &&
                <div className="row">

                    <div className='col-md-12 mb-2'>

                        <h3 className="block uppercase tracking-wide text-gray-400 text-sm font-bold mb-2">Tipo de Cuenta</h3>

                        <div className='col-md-6'>
                            <ul className="grid grid-cols-2 gap-x-5 m-8 ml-0">
                                <li className="relative">
                                    <input
                                        defaultChecked={updateData?.tipoCuenta == 'Ahorro' && true}
                                        className="sr-only peer"
                                        type="radio"
                                        value='Ahorro'
                                        name='tipoCuenta'
                                        id="answer_yes"
                                        onChange={(e) => setFormStep1({ ...formStep1, tipoCuenta: e.target.value })}
                                    />

                                    <label className={` ${errors.tipoCuenta ? 'border-red-500' : 'border-gray-300'} flex justify-center  p-5 ali bg-white border  rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-700 peer-checked:ring-2 peer-checked:border-transparent`} for="answer_yes">
                                        Ahorro
                                    </label>
                                </li>

                                <li className="relative">
                                    <input
                                        defaultChecked={updateData?.tipoCuenta == 'Corriente' && true}
                                        className="sr-only peer"
                                        type="radio"
                                        value='Corriente'
                                        name='tipoCuenta'
                                        id="answer_no"
                                        onChange={(e) => setFormStep1({ ...formStep1, tipoCuenta: e.target.value })}
                                    />
                                    <label className={`${errors.tipoCuenta ? 'border-red-500' : 'border-gray-300'} flex justify-center p-5 bg-white border  rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-700 peer-checked:ring-2 peer-checked:border-transparent`} for="answer_no">
                                        Corriente
                                    </label>


                                </li>


                            </ul>


                        </div>

                    </div>

                    <div data-aos="fade-left" className="col-sm-6">
                        <div className="form-group">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" >
                                Nombre de Banco
                            </label>
                            <input
                                defaultValue={updateData?.nombreBancoReembolso}
                                name='nombreBancoReembolso'
                                onChange={(e) => setFormStep1({ ...formStep1, nombreBancoReembolso: e.target.value })}
                                onBlur={handleBlur}
                                className={`appearance-none block w-full bg-gray-200 text-gray-700 ${errors.nombreBancoReembolso ? "border-2 border-red-500" : 'border border-gray-200'}   rounded py-5 px-4 leading-tight focus:outline-nonefocus:bg-white focus:border-gray-500`}
                                id="grid-last-name"
                                type="text"
                                placeholder="Nombre del Banco"
                            />
                            {
                                errors.nombreBancoReembolso && <span data-aos="zoom-in" className='errrorMsg'> {errors.nombreBancoReembolso}  </span>
                            }

                        </div>

                    </div>

                    <div data-aos="fade-left" className="col-sm-6">
                        <div className="form-group">
                            <label
                                className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" for="grid-last-name">

                                Número de Cuenta Bancaria

                            </label>

                            <InputMask
                                defaultValue={updateData?.numeroCuentaReembolso}
                                name='numeroCuentaReembolso'
                                onChange={handleInput}
                                // onInput={handleInput}
                                // value={formStep1.numeroCuentaReembolso}
                                onBlur={handleBlur}
                                mask="9999-9999-99-9999999999"
                                className={`appearance-none block w-full bg-gray-200 text-gray-700 ${errors.numeroCuentaReembolso ? "border-2 border-red-500" : 'border border-gray-200'} rounded py-5 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500`}
                                id="grid-last-name"
                                type="text"
                                placeholder="Número de cuenta"
                            />
                            {
                                errors.numeroCuentaReembolso && <span data-aos="zoom-in" className='errrorMsg'> {errors.numeroCuentaReembolso}  </span>
                            }

                        </div>
                    </div>
                </div>
            }

            {
                isActiveZinli &&
                <div className="row">
                    <div data-aos="fade-left" className="col-sm-6 mt-5">
                        <div className="form-group">
                            <label className="block uppercase tracking-wide text-gray-700 text-sm font-bold mb-2" >
                                Correo Electrónico de Zinli
                            </label>
                            <input
                                defaultValue={updateData?.emailZinlinReembolso}
                                name='emailZinlinReembolso'
                                onChange={(e) => setFormStep1({ ...formStep1, emailZinlinReembolso: e.target.value })}
                                onBlur={handleBlur}
                                className={`appearance-none block w-full bg-gray-200 text-gray-700 ${errors.emailZinlinReembolso ? "border-2 border-red-500" : 'border border-gray-200'}   rounded py-5 px-4 leading-tight focus:outline-nonefocus:bg-white focus:border-gray-500`}
                                id="grid-last-name"
                                type="text"
                                placeholder="Correo Electrónico de Zinli"
                            />
                            {
                                errors.emailZinlinReembolso && <span data-aos="zoom-in" className='errrorMsg'> {errors.emailZinlinReembolso}  </span>
                            }
                        </div>
                    </div>
                </div>
            }

        </div>
    )
}

export default Steps3